<script>
import BaseButton from '../base/BaseButton.vue';
import { usePillarStore } from '~/store/PillarStore';
import LocLink from '../base/LocLink.vue';
export default {

  components: {
    BaseButton,
    LocLink
  },
  setup() {
    const pillarStore = usePillarStore();
    return {
       pillarStore
    };
  },
  data() {
    return {
      nelsonUrl: 'https://nelsonirrigation.com',
      nelsonContact: {
        imageUri: '/images/NelsonLogoWhite.png',
        number: '509-525-7660',
        email: 'info@nelsonirrigation.com',
        address: [
          '848 Airport Road',
          'Walla Walla, WA 99362',
          'United States'
        ]
      },
      socialMediaData: [
        { link: 'https://www.youtube.com/channel/UCWHgXjUAGesjjaY2fKUi97w', alt:"View Nelson Irrigation's YouTube Channel" , icon: 'https://nelsonirrigation.com/img/social-youtube.svg' },
        { link: 'https://www.instagram.com/nelson_irrigation/',alt:"View Our Instagram" , icon: 'https://nelsonirrigation.com/img/social-instagram.svg' },
        { link: 'https://www.facebook.com/NelsonIrrigationCo', alt:"View Us on Facebook" ,icon: 'https://nelsonirrigation.com/img/social-facebook.svg' },
        { link: 'https://www.linkedin.com/company/nelson-irrigation-corporation', alt:"Find Us on LinkedIn" ,icon: 'https://nelsonirrigation.com/img/social-linkedin.svg' },
        { link: 'https://twitter.com/nelson_irr',alt:"Find us on X" , icon: 'https://nelsonirrigation.com/img/social-twitter.svg' }
      ],
      groups: null,
      languageOptions: []
    };
  },
  mounted() {
    this.languageOptions = this.pillarStore.languages
  },
  computed: {
    langOptions() {
      return this.languageOptions;
    },
    getSocialMediaData() {
      return this.socialMediaData;
    },
    getNelsonContact() {
      return this.nelsonContact;
    },
    NavExtras(){
      return this.$tm('navExtras');
    }
  },
  methods: {
    handleLangSwitch(item) {
      const router = useRouter();
      const switchLocalePath = useSwitchLocalePath();
      router.push({ path: switchLocalePath(item.code) });
      this.pillarStore.selectedLang = item.name;
    }
  }
};
</script>
<template>
  <div class="footer-outer-container">
    <div class="footer-container">
      <div class="footer-contact-box">
        <div>
          <img class="footer-logo" :src="getNelsonContact.imageUri" />
        </div>
        <div class="footer-phone-box">
          <img src="/svg/icon-phone.svg">
          <a class="footer-phone-link" href="tel:509-525-7660">{{ getNelsonContact.number }}</a>
        </div>
        <div class="footer-email-box">
          <img src="/svg/icon-email.svg">
          <a class="footer-email-link" href="mailto:info@nelsonirrigation.com">{{ getNelsonContact.email }}</a>
        </div>
        <div class="footer-address-box">
          <img class="address-icon" src="/svg/icon-address.svg">
          <div>
            <p v-for="item, index in getNelsonContact.address" :key="index" class="footer-address-text">{{
              $rt(item) }}</p>
          </div>
        </div>
        <div class="footer-button-box">
          <LocLink class="footer-button" v-for="item, index in NavExtras.NavFooterButtons" :key="index" :to="$rt(item.url)">
            {{ $rt(item.name) }}
          </LocLink>
        </div>
      </div>
      <div class="footer-divider"></div>
      <div class="footer-page-box">
        <div class="footer-page-details" v-for="item, index in NavExtras.NavFooterLinks" :key="index">
          <div v-for="element, index in item.section" :key="index">
            <h3 class="footer-details-title" :id="'footer-title-' + index">{{ $rt(element.title) }}</h3>
            <div class="footer-page-link-holder">
              <LocLink class="footer-page-link" v-for="page, index in element.pages" :key="index" :to="$rt(page.url)">{{
                $rt(page.name) }}</LocLink>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom-divder"></div>
    <div class="footer-lang-container">
      <div class="footer-lang-box">
        <svg class="langSVG" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="19.32" height="19.32"
          fill="#FFFFFF">
          <path class="lang-icon"
            d="M7.82 1.16a8.8 8.8 0 00-5.38 3.65h3.81c.06 0 .07 0 .08-.07l.15-.58a10.25 10.25 0 011.07-2.59l.27-.41zm9.06 3.66a8.81 8.81 0 00-5.38-3.66l.08.12c.2.36.42.71.6 1.08A12.48 12.48 0 0113 4.73c0 .08 0 .1.12.1h3.78zM7.79 18.14a11 11 0 01-1.48-3.64H2.43a8.78 8.78 0 005.36 3.64zm3.72 0a8.51 8.51 0 003-1.31 8.61 8.61 0 002.32-2.34h-3.8c-.06 0-.07 0-.09.07-.05.23-.11.46-.17.68a10.23 10.23 0 01-1 2.51c-.08.13-.17.25-.26.4zM12 4.82a11.14 11.14 0 00-.78-2.21 4 4 0 00-.93-1.32.82.82 0 00-1.18-.06A3 3 0 008.4 2a9.55 9.55 0 00-1 2.39l-.11.41zm-4.69 9.67A10.42 10.42 0 008.24 17 3.83 3.83 0 009 18a.85.85 0 001.28 0 3.08 3.08 0 00.65-.79 9.21 9.21 0 00.88-2.07c.07-.24.13-.48.2-.73zM13.2 5.8a22.46 22.46 0 01.31 3.37h4.83c0-.31-.06-.61-.11-.91a9 9 0 00-.75-2.38.13.13 0 00-.14-.09H13.2zM1 9.17h4.8a22.48 22.48 0 01.32-3.37H2a.13.13 0 00-.14.1 8.8 8.8 0 00-.78 2.57c0 .23-.05.47-.07.71zm12.2 4.36H17.46c.12-.27.24-.53.34-.81a8.54 8.54 0 00.47-1.87l.06-.64h-4.82a22.47 22.47 0 01-.31 3.38zM1 10.15c0 .29.05.58.1.86a8.92 8.92 0 00.76 2.43c0 .07.07.09.14.09h4.12a22.73 22.73 0 01-.32-3.38zM7.1 5.8a21.14 21.14 0 00-.33 3.37h5.77a20.46 20.46 0 00-.33-3.37zm-.33 4.35v.11c0 .45 0 .91.08 1.36.07.6.16 1.2.23 1.79 0 .09 0 .12.13.12h5a19.88 19.88 0 00.33-3.38zm12.55-.49A9.66 9.66 0 119.65 0a9.67 9.67 0 019.67 9.66z"
            fill-rule="evenodd" data-name="export/icon,-nav,-language"></path>
        </svg>
        <p v-for="lang, index in langOptions" :key="index" class="footer-lang-link"
          @click="this.handleLangSwitch(lang)">{{ lang.name.toUpperCase() }}</p>
      </div>
      <div class="footer-social-media-box">
        <a v-for="item in getSocialMediaData" :alt="item.alt" :href="item.link" :style="'background-image: url(' + item.icon + ')'"
          class="footer-social-media-link">
        </a>
      </div>
    </div>
  </div>
</template>
<style scoped>
.footer-logo {
  width: 220px;
}

.footer-outer-container {
  background-color: #222222;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-container {
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  padding: 40px 0;
  width: 100%;
  max-width: 1200px;

}

.footer-contact-box {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  gap: 15px;
}



.footer-page-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  width: 100%;
}

.footer-button-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
  width: 100%;
  align-items: center;
}

.footer-button {
  text-decoration: none;
  background-color: #3B3B3B;
  border: 2px solid #3B3B3B;
  padding: 1rem 3rem;
  color: #FFFFFF;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  max-width: 260px;
  width: 100%;
}

.footer-button:hover {
  border: 2px solid #F48B41;
  background-color: #222222;

}

.footer-details-title {
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #4e4e4e;
}

.footer-page-details {
  margin-left: 5%;
}

.footer-address-text {
  margin: 0;
}

.footer-divider {
  background-color: #4e4e4e;
  margin: 1rem 5rem;
  height: auto;
  width: 1px;
}

.footer-phone-box,
.footer-email-box,
.footer-address-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.footer-page-link,
.footer-email-link,
.footer-phone-link {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 1.5;
  padding: 6px 0;
  text-decoration: none;
  color: #EFECE0;
  cursor: pointer;

}

.footer-page-link:hover,
.footer-email-link:hover,
.footer-phone-link:hover {
  text-decoration: underline;
}

.footer-lang-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0;

}

.footer-lang-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  color: #FFFFFF;
}

.footer-social-media-box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 5px;
}

.footer-social-media-link {
  margin-left: 8px;
  display: block;
  width: 40px;
  height: 40px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: 1px solid #222222;
  font-size: 0;
}

.footer-social-media-link:hover {
  border: 1px solid #FFFFFF;
  border-radius: 50%;
}

.footer-lang-link {
  cursor: pointer;
}

.footer-lang-link:hover {
  text-decoration: underline;
}

.footer-bottom-divder {
  background-color: #4e4e4e;
  height: 1px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.address-icon {
  align-self: flex-start;
}

@media only screen and (max-width: 1000px) {

  .footer-page-box {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .footer-lang-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 700px) {

  .footer-contact-box {
    align-items: center;
  }

  .footer-container {
    display: flex;
    flex-direction: column;

  }

  .footer-lang-box {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-page-box {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

.footer-page-link-holder {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 8px;
}

@media only screen and (max-width: 1000px) {

  .footer-page-details {
    margin-left: 0;
  }

  .footer-divider {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .footer-page-link-holder {
    grid-template-columns: 1fr 1fr;
  }

  .footer-page-link {
    margin-right: 12px;

  }
}
</style>
