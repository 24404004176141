<script>
import LanguageSwitcher from './LanguageSwitcher.vue';
import LocLink from '../base/LocLink.vue';
import { usePillarStore } from '~/store/PillarStore';
export default {
  components: {
    LanguageSwitcher, LocLink
  },
  props: ['isTop'],
  data() {
    return {
      nelsonUrl: 'https://nelsonirrigation.com',
      isCopied: false,
      isShow: false,
      indexData: [
        {
          name: 'Terms of Use',
          path: '/legal/terms',
        },
        {
          name: 'Privacy Policy',
          path: '/legal/privacy',
        },
        {
          name: 'Warranty',
          path: '/legal/warranty',
        },
        {
          name: 'Product Performance Notes',
          path: '/legal/product-performance-notes',
        },
        {
          name: '© 2024 Nelson Irrigation',
        },
      ],
      navBarData: null
    };
  },
  mounted() {



  },
  computed: {
    getNavBarData() {

      let navBar = this.$tm('navExtras.NavBar');

      // todo couldn't get this to work so moved .Other into .NavBar and using v-if for filtering
      let navOther = this.$tm('navExtras.Other');
      let navAll = [...navBar, ...navOther];

      return navBar; // this.$tm('navExtras.NavBar');
    },

    getIndexData() {
      return this.indexData;
    },

    getIsTop() {
      if (this.$props.isTop === false) {
        return this.$props.isTop;
      } else {
        return true;
      }
    },

    getIsCopied() {
      return this.isCopied;
    },
    getUtilClass() {
      return (this.$props.isTop) ? 'utilBG' : '';
    },
    getMenuOpen () {
      console.log('changed');
      return (!usePillarStore().getMobileMenuOpen) ? 'mobile-options-open' : '' ;
    }

  },
  methods: {

    handleMenu() {

      if(!usePillarStore().getMobileMenuOpen) {
        usePillarStore().setMobileMenuOpen(true);
        this.$refs.barHolder.classList.add('mobile-options-open');
      } else {
        usePillarStore().setMobileMenuOpen(false);
        this.$refs.barHolder.classList.remove('mobile-options-open');
      }

      this.isShow = !this.isShow;

    },
    handleCopyUrl() {
      const currentRoute = this.$router.currentRoute.value.fullPath;
      const link = 'https://nelsonirrigation.com' + currentRoute;
      navigator.clipboard.writeText(link);
      this.isCopied = true;
    },
    getVisibility (url) {

      let r = ""
      
      switch(url) {
        case "/resources":
        case "/dealers":  
          r = "hide-desktop"
        case "/dealerportal":
          r = "hide-mobile"
      }
      return r;
      
    }
  },
};
</script>
<template>
  <div :class="getUtilClass">
    <section v-if="getIsTop === false" class="bar-bottom-container">
      <div class="bar-bottom">
        <div class="bar-title-container">
          <div class="bar-title-box" v-for="(item, index) in getIndexData" :key="index">
            <LocLink class="bar-title-links" v-if="item.name != '© 2023 Nelson Irrigation'" :to="item.path">{{ item.name
              }}</LocLink>
            <p v-else>{{ item.name }}</p>
          </div>
        </div>
        <div class="bar-link-box" @click="this.handleCopyUrl()">
          <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
            <g class="rdLinkIcon" fill="#fff" fill-rule="nonzero">
              <path
                d="M8.987 6.367H6.916c-1.524 0-2.764 1.212-2.764 2.713 0 1.5 1.24 2.713 2.764 2.713h5.018c1.524 0 2.764-1.212 2.764-2.713 0-.849-.4-1.632-1.067-2.142a.5.5 0 10-.608.794c.424.324.675.816.675 1.348 0 .943-.787 1.713-1.764 1.713H6.916c-.976 0-1.764-.77-1.764-1.713 0-.943.788-1.713 1.764-1.713h2.07a.5.5 0 000-1z">
              </path>
              <path
                d="M7.013 9.633h2.071c1.524 0 2.764-1.212 2.764-2.713 0-1.5-1.24-2.713-2.764-2.713H4.066c-1.524 0-2.764 1.212-2.764 2.713 0 .849.4 1.632 1.067 2.142a.5.5 0 00.608-.794 1.692 1.692 0 01-.675-1.348c0-.943.787-1.713 1.764-1.713h5.018c.976 0 1.764.77 1.764 1.713 0 .943-.788 1.713-1.764 1.713H7.013a.5.5 0 000 1z">
              </path>
            </g>
          </svg>
          <p v-if="getIsCopied === false">Copy Page URL</p>
          <p v-if="getIsCopied">Copied!</p>
        </div>
      </div>
    </section>
    <section class="top-nav" v-if="getIsTop">

      <div class="left-nav-group">
        <div class="mobile-menu" @click="handleMenu">
          <img v-if="!isShow" src="/svg/hamb.svg" />
          <img v-else class="close-btn" src="/svg/close-black.svg" />
        </div>
        <LocLink class="mobile-logo" :to="'/'">
          <img class="logo-svg" alt="Nelson Irrigation Corporation" src="/svg/nelson-logo.svg" />
        </LocLink>
      </div>
      <div ref="barHolder" class="bar-holder mobile-options" >
        <div class="bar-title-top" v-for="(item, index) in getNavBarData" :class="getVisibility($rt(item.url))" >
          <!--               v-if="$rt(item.url) !== '/resources' && $rt(item.url) !== '/dealers' &&   $rt(item.url) !== '/dealerportal'"-->
          <LocLink class="un-link" :to="$rt(item.url)"
                   v-if="$rt(item.url) !== '/dealerportal'"

          >
            {{ $rt(item.name) }}</LocLink>
          <a class="un-link" v-if="$rt(item.url) === '/dealerportal'" href="/dealerportal">{{ $rt(item.name) }}</a>
        </div>
      </div>


      <LanguageSwitcher />

    </section>
  </div>
</template>

<style scoped>

.top-nav {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding-right: 10px;
}

.utilBG {
  background-color: #222222;
}

.bar-title-links {
  color: #FFFFFF;
}

.bar-title-links {
  color: #ffffff;
  text-decoration: none;
}

.bar-bottom-container {
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 17px 0;
  max-width: none;
  display: flex;
}

.bar-title-top {
  display: flex;
}

.bar-bottom {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem 0;
}

.bar-title-box {
  display: flex;
  flex-direction: row;
}

.bar-link-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
}

.bar-divider {
  height: 25px;
  background-color: #4e4e4e;
  width: 1px;
}

.nav-logo-link {
  display: none;
}

.bar-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  justify-content: space-between;
  gap: 16px;
}

.bar-title-top-mobile {

  height: 48px;
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.bar-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
}

.bar-link-box:hover {
  text-decoration: underline;
}

.mobile-menu {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.left-nav-group {
  display: none;
  align-items: center;
}

.mobile-logo {
  height: 25px;
}

.logo-svg {
  width: 100%;
  height: 100%;
}

.hide-desktop {

}

.hide-mobile {
  display: none;
}

.bar-holder {
  display: flex;
}

.un-link {
  color: #fff;
  padding: 10px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
}

.un-link:hover {
  text-decoration: underline;
}

.close-btn {
  transform: scale(1.3);
}


@media only screen and (max-width: 700px) {

  .hide-desktop {
    display: none;
  }

  .hide-mobile {
    display: flex;
  }

  .utilBG {
    height: 50px;
    background-color: white;
    user-select: none;
  }

  .utilBGBottom {
    height: auto !important;
    padding: 20px 0;
  }

  .bar-title-links {
    font-size: 13px;
  }

  .bar-title-container {
    gap: 10px;
  }

  .top-nav {
    justify-content: space-between;
  }

  .bar-title-top-mobile {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid gray;
    margin-left: 20px;
  }

  .bar-title-top-box {
    background-color: #222222;
    z-index: 999;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
  }

  .bar-bottom,
  .bar-title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bar-title-top-mobile:last-of-type~.bar-links {
    border: none;
    padding-bottom: 0;
  }

  .bar-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .left-nav-group {
    display: flex !important;
  }
}


@media only screen and (max-width: 700px) {

  .bar-holder {
    position: absolute;
    flex-direction: column;
    z-index: 100;
    width: 100vw;
    background-color: #222222;
    top:50px;
    left: 0px;
  }

  .mobile-options {
    display: none;
  }

  .mobile-options-open {
    display: flex;
  }

  .un-link {
    color: #fff;
    padding: 20px;
    text-decoration: none;
    font-size: 15px;
    border-bottom: 1px solid #4E4E4E;
    width: 100%;
  }


}

</style>
