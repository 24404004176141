<script>
import { gsap } from 'gsap';
import { usePillarStore } from '@/store/PillarStore';
export default {
  data() {
    return {
      languages: [],
      isSelected: false,
      isOptions: false,
      currentLoc: null
    };
  },
  components: {
  },
  mounted() {
    const { locale, locales} = useI18n();
    let loc = locales.value.find(i => i.code === locale.value);
    this.pillarStore.selectedLang = loc.name;
    this.languages = locales.value;
    this.currentLoc = locale.value;
  },
  computed: {
    availableLocales() {
      if(this.languages.length > 0)
        return this.languages.filter(i => i.code !== this.currentLoc);
      else return this.languages;
    },
    langSelection() {
      return this.pillarStore.selectedLang;
    },
    lang() {
      return this.pillarStore.languages;
    },
    Selected() {
      if (this.isSelected === null || this.isSelected === undefined) { return false; }
      else { return this.isSelected; }
    },
    Options() {
      if (this.isOptions === null || this.isOptions === undefined) { return false; }
      else { return this.isOptions; }
    },
    showDropDown() {

    }
  },
  methods: {
    switchLocale(locCode) {
      const switchLocalePath = useSwitchLocalePath();
      switchLocalePath(locCode);
    },

    handleDropDrown() {
      this.isOptions = this.isOptions ? false : true;
      this.isSelected = this.isSelected ? false : true;

      //usePillarStore().setNavOpen(true);
    },
    handleFocusOut() {
      this.isOptions = false;
      this.isSelected = false;
    },

    handleHover(num) {
      const id = '#lang' + num;
      gsap.to(id, {
        duration: '0.1',
        backgroundColor: '#0089D0'
      });
    },
    handleLeave(num) {
      const id = '#lang' + num;
      gsap.to(id, {
        duration: '0.1',
        backgroundColor: '#222222'
      });
    },
    async handleOptions(item) {
      const switchLocalePath = useSwitchLocalePath();
      this.currentLoc = item.code;
      await navigateTo({ path: switchLocalePath(item.code) });
      this.pillarStore.selectedLang = item.name;

      this.isOptions = false;
      this.isSelected = false;
    }
  },
  setup() {
    const pillarStore = usePillarStore();
    return {
      pillarStore
    };
  },
};
</script>
<template>
  <div class="language-switcher" ref="switcher" tabindex="0" @focusout="handleFocusOut">
    <div @click="handleDropDrown" :v-model="$i18n.defaultLocale" class="un-lang">
      <svg width="19.32" height="19.32" fill="white" class="world-svg">
        <path
          d="M7.82 1.16a8.8 8.8 0 00-5.38 3.65h3.81c.06 0 .07 0 .08-.07l.15-.58a10.25 10.25 0 011.07-2.59l.27-.41zm9.06 3.66a8.81 8.81 0 00-5.38-3.66l.08.12c.2.36.42.71.6 1.08A12.48 12.48 0 0113 4.73c0 .08 0 .1.12.1h3.78zM7.79 18.14a11 11 0 01-1.48-3.64H2.43a8.78 8.78 0 005.36 3.64zm3.72 0a8.51 8.51 0 003-1.31 8.61 8.61 0 002.32-2.34h-3.8c-.06 0-.07 0-.09.07-.05.23-.11.46-.17.68a10.23 10.23 0 01-1 2.51c-.08.13-.17.25-.26.4zM12 4.82a11.14 11.14 0 00-.78-2.21 4 4 0 00-.93-1.32.82.82 0 00-1.18-.06A3 3 0 008.4 2a9.55 9.55 0 00-1 2.39l-.11.41zm-4.69 9.67A10.42 10.42 0 008.24 17 3.83 3.83 0 009 18a.85.85 0 001.28 0 3.08 3.08 0 00.65-.79 9.21 9.21 0 00.88-2.07c.07-.24.13-.48.2-.73zM13.2 5.8a22.46 22.46 0 01.31 3.37h4.83c0-.31-.06-.61-.11-.91a9 9 0 00-.75-2.38.13.13 0 00-.14-.09H13.2zM1 9.17h4.8a22.48 22.48 0 01.32-3.37H2a.13.13 0 00-.14.1 8.8 8.8 0 00-.78 2.57c0 .23-.05.47-.07.71zm12.2 4.36H17.46c.12-.27.24-.53.34-.81a8.54 8.54 0 00.47-1.87l.06-.64h-4.82a22.47 22.47 0 01-.31 3.38zM1 10.15c0 .29.05.58.1.86a8.92 8.92 0 00.76 2.43c0 .07.07.09.14.09h4.12a22.73 22.73 0 01-.32-3.38zM7.1 5.8a21.14 21.14 0 00-.33 3.37h5.77a20.46 20.46 0 00-.33-3.37zm-.33 4.35v.11c0 .45 0 .91.08 1.36.07.6.16 1.2.23 1.79 0 .09 0 .12.13.12h5a19.88 19.88 0 00.33-3.38zm12.55-.49A9.66 9.66 0 119.65 0a9.67 9.67 0 019.67 9.66z" />
      </svg>
      <div class="dropdown-selected-lang">{{ langSelection }}</div>
      <div class="lang-dropdown-icon-holder">
        <img v-show="Selected === false" src="/svg/chevron-down-white.svg" class="lang-down lang-dropdown-icon">
        <img v-show="Selected === true" src="/svg/close.svg" class="lang-open-close lang-dropdown-icon">
      </div>
    </div>
    <div ref="languageOptions" id="myDropdown" class="langs" v-show="Options">
      <div class="language-option" @mouseover="this.handleHover(index)" @mouseleave="this.handleLeave(index)"
        v-for="lang, index in availableLocales" :key="index" @click="handleOptions(lang)" :id="'lang' + index">
        <p class="lang-other" :value="lang.code">{{ lang.name }}</p>
      </div>
    </div>
  </div>

</template>

<style scoped>
.langs {
  position: absolute;
  top: 42px;
  background: #222222;
  width: 180px;
  z-index: 4;
}

.dropbtn {
  user-select: none;
  background-color: #222222;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 15px 15px;
  gap: 10px;
  font-size: 15px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover div:nth-child(2) {
  text-decoration: underline;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

::-webkit-scrollbar-thumb {
  background: #FFFFFF;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  background: #222222;
}

.world-svg {
  margin-right: 7px;
}

.dropdown-selected-lang {
  margin-right: 10px;
  font-size: 12px;
}

.lang-dropdown-icon {
  width: 15px;
  max-width: 15px;
}

.lang-open-close {
  transform: scale(.8) translateY(0px);
}

.language-option {
  cursor: pointer;
}

@media only screen and (max-width: 700px) {

  .world-svg {
    fill: black;
  }

  .lang-dropdown-icon-holder {
    display: none;
  }

  .un-lang {
    margin: 0;
  }

}
</style>