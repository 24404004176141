<script>
import SearchBar from './SearchBar.vue';
import LocLink from '../base/LocLink.vue';
import { usePillarStore } from '~/store/PillarStore';
import { useUtilityStore } from '~/store/UtilityStore';
import BaseArrow from '~/components/base/BaseArrow.vue';
import { useI18n } from "vue-i18n";
import NavAlphaScroll from "~/components/main/nav/NavAlphaScroll.vue";

export default {
  components: {
    SearchBar,
    LocLink,
    BaseArrow,
    NavAlphaScroll
  },
  data() {
    return {
      groups: null,
      subStoryItems: [],
      selectedGroup: null,
      selectedSubGroup: null,
      selectedArrow: null,
      navOpen: false,
      routeNavSelected: '',
      isScrolling: false,
      isTransparent: true,
      isSolid: false,
      searchIsOpen: false
    };
  },
  setup() {
    const pillarStore = usePillarStore();
    const i18nLocale = useI18n();
    // handles whether the nav menu should be visible or not
    const visible = ref(true);
    const handleClickOutside = (event) => {
      const container = document.querySelector('.nav-open');
      if (container && !container.contains(event.target.parentNode)) {
        visible.value = false;
      } else {
        visible.value = true;
      }
    };
    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });
    return {
      visible,
      pillarStore,
      i18nLocale
    };
  },
  mounted() {
    this.updateBG();
  },


  watch: {
    $route(to) {
      var currentLocale = this.i18nLocale.locale.value;
      if (currentLocale === 'en')//no leading path
      {
        var paths = to.path.split('/');
        paths.shift();
        this.routeNavSelected = paths.shift();
      }
      else {
        var paths = to.path.split('/');
        paths.shift();
        paths.shift();
        this.routeNavSelected = paths.shift();
      }

      // handle background transparency state
      this.updateBG();

    },
  },
  computed: {
    terms() {
      const terms = this.$tm('extraTerms');
      return terms;
    },
    navTerms() {
      const terms = this.$tm('extraTerms');
      var solutionTerm = terms.find((element) => {
        if (element.mlVar != 'NULL' && element.mlVar != null) {
          return this.$rt(element.mlVar) === 'solutions';
        }
      });
      var productsTerm = terms.find((element) => {
        if (element.mlVar != 'NULL' && element.mlVar != null) {
          return this.$rt(element.mlVar) === 'products';
        }
      });
      var cropsTerm = terms.find((element) => {
        if (element.mlVar != 'NULL' && element.mlVar != null) {
          return this.$rt(element.mlVar) === 'crops';
        }
      });
      return {
        solutions: { display: this.$rt(solutionTerm.mltText), url: 'solutions' },
        products: { display: this.$rt(productsTerm.mltText), url: 'products' },
        crops: { display: this.$rt(cropsTerm.mltText), url: 'crops' },
      }
    },
    getTransparentState() {
      return (!this.isTransparent) ? "nav-dark" : "";
    },
    getNavBG() {
      return (!this.isTransparent) ? "white-bg" : "";
    },
    getNavDividerState() {
      return (!this.isTransparent) ? "nav-divider-dark" : "";
    },
    getIsSearch() {
      return this.pillarStore.isSearch;
    },
    getNavOpen() {
      return this.pillarStore.getNavOpen;
    },
    Groups() {
      var navData = this.$tm('nav');
      this.groups = Object.entries(navData);//convert to array
      return this.groups;
    },
    Crops() {
      var navData = this.$tm('nav-crops');
      return navData;
    },
    Products() {
      var navData = this.$tm('nav-products');
      if (navData.filter)
        navData = navData.filter((prod) => prod.isModel === 0);
      var grouped = useUtilityStore().groupResourcesBy(navData, 'productFamilyID', this.$rt);
      var groupedArray = Object.entries(grouped);
      var tmp = [];
      groupedArray.forEach((ga) => {
        tmp.push(ga[1]);
      })
      tmp.sort((a, b) => a[0].productFamilysortorder - b[0].productFamilysortorder);
      return tmp;
    },
    Solutions() {
      //testing new nav data
      var navData = this.$tm('nav-solutions');
      var grouped = useUtilityStore().groupResourcesBy(navData, 'solutionGroupID', this.$rt);
      var groupedArray = Object.entries(grouped);
      var tmp = [];
      groupedArray.forEach((ga) => {
        tmp.push(ga[1]);
      })
      tmp.sort((a, b) => a[0].solutionSortorder - b[0].solutionSortorder);
      return tmp;
    },
    getSelectedGroup() {
      return this.selectedGroup;
    },
    getSelectedSubGroup() {
      return this.selectedSubGroup;
    },
    getSolidState() {
      return this.isSolid ? "nav-holder-solid" : "";
    }
  },
  methods: {

    updateBG() {

      console.log("current route", useRouter().currentRoute.value.fullPath);
      if (useRouter().currentRoute.value.fullPath !== "/") {
        this.isTransparent = false;
        this.isSolid = true;
      } else {
        this.isTransparent = true;
        this.isSolid = false;
      }

    },

    handleGroupClick(groupUrl) {
      if (!groupUrl)
        return;
      //need to check url here since we may have just switched languages and the selectedgroup may be in another locale
      if (!this.selectedGroup || this.selectedGroup.url !== groupUrl) {
        this.selectedGroup = this.navTerms[groupUrl];
        this.selectedSubGroup = null;
        this.pillarStore.setNavOpen(true);
        this.isScrolling = false;
        if (!this.isSolid) {
          this.isTransparent = false;
        }
      } else {
        this.selectedGroup = null;
        this.selectedSubGroup = null;
        this.isScrolling = false;
        if (!this.isSolid) {
          this.isTransparent = true;
        }
        this.handleNavBlockClick();
      }
    },
    handleSubGroupClick(subGroup, index) {
      this.selectedSubGroup = subGroup;
      if (this.selectedGroup.url === 'products') {
        this.selectedSubGroup.sort((a, b) => {
          if (this.$rt(a.productSortorder) < this.$rt(b.productSortorder)) { return -1; }
          if (this.$rt(a.productSortorder) > this.$rt(b.productSortorder)) { return 1; }
          return 0;
        })
      }
      let arrow = this.$refs['arrow' + index];
      if (this.$data.selectedArrow !== null) {
        this.$data.selectedArrow.handleOut();
      }
      this.$data.selectedArrow = arrow[0];
      arrow[0].handleOver();

    },
    handleSearch() {
      this.pillarStore.isSearch = true;
      this.pillarStore.setNavOpen(true);
      if (!this.isSolid) {
        this.isTransparent = false;
      }
      this.searchIsOpen = true;
    },

    handleSearchClosed() {

      this.pillarStore.isSearch = false;
      this.pillarStore.setNavOpen(false);
      if (!this.isSolid) {
        this.isTransparent = true;
      }
      this.searchIsOpen = false;

      let highlight = document.getElementById("nav-highlight-search")
      highlight.style.backgroundColor = "transparent";

    },

    handleGroupOver(e) {

      let highlight = e.currentTarget.querySelector('.nav-highlight');
      highlight.style.backgroundColor = this.isTransparent ? 'white' : 'black';

    },
    handleGroupOut(e) {

      let highlight = e.currentTarget.querySelector('.nav-highlight');
      highlight.style.backgroundColor = 'transparent';

    },
    handleNavBlockClick() {
      this.selectedGroup = null;
      this.selectedSubGroup = null;
      if (!this.isSolid) {
        this.isTransparent = true;
      }
      this.pillarStore.setNavOpen(false);
      if (this.pillarStore.getMobileMenuOpen) {
        this.pillarStore.setMobileMenuOpen(false);
      }
    },
    hideMenu(e) {
      e.stopImmediatePropagation();
      this.handleNavBlockClick();
    },
    handleAlphaScroll(alpha) {

      let links = this.$refs.secondColLinks;
      let items = Array.from(links.children);
      let first = items.find((item) => {
        return Array.from(item.id)[0] === alpha
      })

      links.parentNode.style.scrollBehavior = 'smooth';

      if (alpha === "#") {
        console.log(links, ' links');
        links.parentNode.scrollTop = 0;
      } else {
        links.parentNode.scrollTop = first.offsetTop;
      }

    },
    getProductsName(subItem) {

      let str = subItem ? this.$rt(subItem.name) : "";
      return str.replace(/[™®©]/g, '<sup>$&</sup>');

    },
  }
};
</script>
<template>
  <div ref="nav" class="nav-holder" :class="getSolidState" data-swiftype-index="false" v-if="terms !== null">
    <div class="navBG" :class="getNavBG">
      <div class="nav-area">
        <LocLink class="nelson-logo" :to="'/'">
          <img src="/svg/nelson-logo-Nsized.svg" />
          <img src="/svg/nelson-logo-NelsonSized.svg" class="logo-nelson-sized" :class="getTransparentState" />
        </LocLink>
        <div class="nav-links">
          <div class="nav-group" id="nav-group-solutions" @mouseover="handleGroupOver" @mouseout="handleGroupOut">
            <div class="nav-link" @click="handleGroupClick('solutions')">
              <img class="nav-icon"
                v-if="(getSelectedGroup !== null && getSelectedGroup.url === 'solutions') || 'solutions' === routeNavSelected"
                :src="'/svg/solutions-orange.svg'" :class="getTransparentState" />
              <img class="nav-icon" v-else :src="isTransparent ? '/svg/solutions-white.svg' : '/svg/solutions.svg'"
                :class="getTransparentState" />
              <div class="nav-text" :class="getTransparentState"> {{ navTerms.solutions.display }}
              </div>
              <div class="nav-expand" :class="getTransparentState">
                <img
                  v-show="(getSelectedGroup !== null && getSelectedGroup.url !== 'solutions') || getSelectedGroup === null"
                  src="/svg/chevron-down.svg" class="chevron-down">
                <img v-show="getSelectedGroup !== null && getSelectedGroup.url === 'solutions'"
                  src="/svg/close-black.svg" class="close-black" @click="hideMenu">
              </div>
            </div>
            <div class="nav-highlight"></div>
          </div>
          <div class="nav-divider" :class="getNavDividerState" />
          <div class="nav-group" id="nav-group-crops" @mouseover="handleGroupOver" @mouseout="handleGroupOut">
            <div class="nav-link" @click="handleGroupClick('crops')">
              <img class="nav-icon"
                v-if="(getSelectedGroup !== null && getSelectedGroup.url === 'crops') || 'crops' === routeNavSelected"
                :src="'/svg/crops-orange.svg'" :class="getTransparentState" />
              <img class="nav-icon" v-else :src="isTransparent ? '/svg/crops-white.svg' : '/svg/crops.svg'"
                :class="getTransparentState" />
              <div class="nav-text" :class="getTransparentState"> {{ navTerms.crops.display }}
              </div>
              <div class="nav-expand" :class="getTransparentState">
                <img
                  v-show="(getSelectedGroup !== null && getSelectedGroup.url !== 'crops') || getSelectedGroup === null"
                  src="/svg/chevron-down.svg" class="chevron-down" @click="handleGroupClick(grp)">
                <img v-show="getSelectedGroup !== null && getSelectedGroup.url === 'crops'"
                  src="/svg/close-black.svg" class="close-black" @click="hideMenu">
              </div>
            </div>
            <div class="nav-highlight"></div>
          </div>
          <div class="nav-divider" :class="getNavDividerState" />
          <div class="nav-group" id="nav-group-products" @mouseover="handleGroupOver" @mouseout="handleGroupOut">
            <div class="nav-link" @click="handleGroupClick('products')">
              <img class="nav-icon"
                v-if="(getSelectedGroup !== null && getSelectedGroup.url === 'products') || 'products' === routeNavSelected"
                :src="'/svg/products-orange.svg'" :class="getTransparentState" />
              <img class="nav-icon" v-else :src="isTransparent ? '/svg/products-white.svg' : '/svg/products.svg'"
                :class="getTransparentState" />
              <div class="nav-text" :class="getTransparentState">
                {{ navTerms.products.display }} </div>
              <div class="nav-expand" :class="getTransparentState">
                <img
                  v-show="(getSelectedGroup !== null && getSelectedGroup.url !== 'products') || getSelectedGroup === null"
                  src="/svg/chevron-down.svg" class="chevron-down">
                <img v-show="getSelectedGroup !== null && getSelectedGroup.url === 'products'"
                  src="/svg/close-black.svg" class="close-black" @click="hideMenu">
              </div>
            </div>
            <div class="nav-highlight"></div>
          </div>
          <div class="nav-divider" :class="getNavDividerState" />
          <div class="nav-group  nav-hidden-element" id="nav-group-resources" @mouseover="handleGroupOver"
            @mouseout="handleGroupOut">
            <LocLink class="nav-link nav-nuxt" :to="$t('navExtras.Other[0].url')" @click="hideMenu">
              <img :src="isTransparent ? '/svg/resources-white.svg' : '/svg/resources.svg'" class="nav-icon"
                :class="getTransparentState" />
              <div class="nav-text" :class="getTransparentState">{{ $t('navExtras.Other[0].name') }}</div>
            </LocLink>
            <div class="nav-highlight"></div>
          </div>
          <div class="nav-divider" :class="getNavDividerState" />
          <div class="nav-group  nav-hidden-element" id="nav-group-dealers" @mouseover="handleGroupOver"
            @mouseout="handleGroupOut">
            <LocLink class="nav-link nav-nuxt" :to="$t('navExtras.Other[1].url')">
              <img :src="isTransparent ? '/svg/find a dealer-white.svg' : '/svg/find A dealer.svg'" class="nav-icon"
                :class="getTransparentState" />
              <div class="nav-text" :class="getTransparentState">{{ $t('navExtras.Other[1].name') }}</div>
            </LocLink>
            <div class="nav-highlight"></div>
          </div>
          <div class="nav-divider" :class="getNavDividerState" />
          <div class="nav-group" id="nav-group-search" @mouseover="handleGroupOver" @mouseout="handleGroupOut">
            <div class="nav-link nav-nuxt" id="search" @click="handleSearch">
              <img :src="isTransparent ? '/svg/search-white.svg' : '/svg/search.svg'" class="nav-icon"
                :class="getTransparentState" />
              <div class="nav-text" :class="getTransparentState">{{ $t('navExtras.Other[2].name') }}</div>
            </div>
            <div class="nav-search-box" v-show="getIsSearch">
              <SearchBar @search-closed="handleSearchClosed" />
            </div>
            <div id="nav-highlight-search" class="nav-highlight"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-on-top" v-show="getNavOpen">
      <div class="mega-nav" v-if="getSelectedGroup" v-show="$rt(getSelectedGroup.url) === 'crops'">
        <div class="crops-column">
          <LocLink class="mn-link crops-flex" v-for="subItem, index in Crops" :key="index"
            :to="'/crops/' + $rt(subItem.cropURL)" @click="hideMenu">
            {{ subItem ? $rt(subItem.crop) : "" }}
          </LocLink>
        </div>
      </div>
      <div class="mega-nav" v-if="getSelectedGroup && getSelectedGroup.url === 'solutions'"
        v-show="$rt(getSelectedGroup.url) === 'solutions'">
        <div class="mn-col">
          <div class="mn-link" v-for="solGrp, index in Solutions" :key="index"
            :class="getSelectedSubGroup !== null && getSelectedSubGroup[0].solutionGroup === solGrp[0].solutionGroup ? 'mn-link-on' : ''"
            @click="handleSubGroupClick(solGrp, index)">
            {{ $rt(solGrp[0].solutionGroup) }}
            <div class="sub-group-arrow">
              <BaseArrow :ref="'arrow' + index" color="white" hover-color="white" />
            </div>
          </div>
        </div>
        <div class="nav-sub-menus">
          <div ref="secondCol" class="mn-col mn-secondcol mn-lightblue solutions-nav">

            <LocLink class="mn-link second-col-link" v-if="getSelectedSubGroup"
              v-for="solution, index in getSelectedSubGroup"
              ref="$rt(solution.solution).toLowerCase().replaceAll('\\s', '').replaceAll('[^a-zA-Z0-9]+', '-')"
              id="$rt(solution.solution).toLowerCase().replaceAll('\\s', '').replaceAll('[^a-zA-Z0-9]+', '-')"
              :to="'/solutions/' + $rt(solution.solutionGroupURL) + '/' + $rt(solution.solutionURL)" @click="hideMenu">

              {{ solution ? $rt(solution.solution) : "" }}
            </LocLink>
          </div>
          <div class="mn-col mn-thirdcol hideMobile mn-darkblue">
            <LocLink class="mn-link third-col-link" v-if="getSelectedSubGroup !== null"
              :to="'/solutions/' + $rt(getSelectedSubGroup[0].solutionGroupURL)" @click="hideMenu">
              View All
            </LocLink>
          </div>
        </div>

      </div>
      <div class="mega-nav" v-if="getSelectedGroup && getSelectedGroup.url === 'products'"
        v-show="$rt(getSelectedGroup.url) === 'products'">
        <div class="mn-col">
          <div class="mn-link" v-for="prodFam, index in Products" :key="index"
            :class="getSelectedSubGroup !== null && getSelectedSubGroup[0].productFamily === prodFam[0].productFamily ? 'mn-link-on' : ''"
            @click="handleSubGroupClick(prodFam, index)">
            <div v-html="$rt(prodFam[0].productFamily).replace(/[™®©]/g, '<sup>$&</sup>')"></div>
            <div class="sub-group-arrow">
              <BaseArrow :ref="'arrow' + index" color="white" hover-color="white" />
            </div>
          </div>
        </div>
        <div class="nav-sub-menus">
          <div class="mn-col mn-secondcol mn-lightblue">
            <div class="second-col-links-holder" ref="secondColLinks">
              <LocLink class="mn-link second-col-link" v-if="getSelectedSubGroup"
                v-for="product, index in getSelectedSubGroup" :key="index"
                :to="'/products/' + $rt(getSelectedSubGroup[0].productFamilyURL) + '/' + $rt(product.productURL)"
                @click="hideMenu"
                :ref="$rt(product.product).toLowerCase().replace(/\s+/g, '-').replaceAll('[^a-zA-Z0-9]+', '-')"
                :id="$rt(product.product).toLowerCase().replace(/\s+/g, '-').replaceAll('[^a-zA-Z0-9]+', '-')">
                <div v-html="$rt(product.product)"></div>
              </LocLink>
            </div>
            <NavAlphaScroll v-show="isScrolling" @handle-a-scroll="handleAlphaScroll" />
          </div>
          <div class="mn-col mn-thirdcol hideMobile mn-darkblue">
            <LocLink class="mn-link third-col-link" v-if="getSelectedSubGroup"
              :to="'/products/' + $rt(getSelectedSubGroup[0].productFamilyURL)" @click="hideMenu">
              View All
            </LocLink>
            <LocLink v-if="getSelectedSubGroup" v-show="getSelectedSubGroup[0].pfHasStory === 1"
              class="mn-link third-col-link"
              :to="'/products/' + $rt(getSelectedSubGroup[0].productFamilyURL) + '/story'" @click="hideMenu">
              <div
                v-html="'The ' + $rt(getSelectedSubGroup[0].productFamily).replace(/[™®©]/g, '<sup>$&</sup>') + ' Story'">
              </div>
            </LocLink>
          </div>
        </div>

      </div>
    </div>

    <div class="nav-block-overlay" v-show="getNavOpen" @click="handleNavBlockClick" />
  </div><!--allNav-->
</template>
<style scoped>
.nav-search-box {
  position: absolute;
  top: 70px;
  right: 30px;
  z-index: 999999;
}

.mn-col {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 5px;
}

.mn-secondcol {
  /*  height: fit-content;*/
  overflow-y: auto;
  background-color: #0b2644;
  display: flex;
  flex-direction: row !important;
}

.mn-secondcol .mn-link {
  background-image: linear-gradient(#1A95D4, #1A95D4);
}

.mn-secondcol .mn-link:hover {
  background-image: linear-gradient(#fff, #fff)
}

.navBG {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background: transparent;
  transition: background .25s;
}

.nelson-logo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.nav-area {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.nav-on-top {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 1200px;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  height: 100%;
}

.nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.mn-link {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(to bottom, #4E4E4E, #4E4E4E);
  background-repeat: no-repeat;
  background-position: 1.5em 100%;
  background-size: 100% 1px;
  padding: .8em .5em .8em 1.5em;
  color: #fff;
  cursor: pointer;
  max-height: 41px;
}

.mn-link:hover {
  background-image: linear-gradient(#fff, #fff);
}

.mega-nav {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  background-color: #3B3B3B;
  max-height: 575px;
}

.second-col-link {
  background-image: linear-gradient(#1A95D4, #1A95D4);
}

.crops-column {
  display: flex;
  flex-direction: column;
  max-height: 415px;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 5px;
}

.crops-flex {
  width: 33.3333%;
}

.mn-lightblue {
  background: #0089D0;
}

.mn-darkblue {
  background: #007BBA;
}

.mn-darkblue:empty {
  background: #0089D0;
}

@media only screen and (max-width: 500px) {
  .mega-nav {
    width: 100%;
  }

  .crops-column {
    max-height: 500px;
  }
}

.nav-text {
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}

.nav-icon {
  width: 21px;
  height: 21px;
  /* filter: invert(1);*/
}

.nav-dark {
  filter: none !important;
  color: black;

}


.nav-icon-open {
  width: 15px;
  max-width: 15px;
}

.nav-close {
  width: 10px;
  height: 10px;
}

.nav-divider {
  border-left: 1px solid black;
  background-color: black;
  margin-left: 20px;
  margin-right: 20px;
  height: 50%;
}

.nav-nuxt {
  text-decoration: none;
  color: black;
}

.nav-highlight {
  width: 90%;
  height: 3px;
  background-color: transparent;
}

.nav-group {
  display: flex;
  flex-direction: column;
  height: 80%;
  align-items: center;
  justify-content: center;
}

.nav-loop {
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;
}

.nav-block-overlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);

}

.nav-sub-menus {
  display: flex;
  width: 100%;
  flex: 2;
}

.second-col-links-holder {
  display: flex;
  flex-direction: column;
}

.nav-holder-solid {
  position: initial !important;
}


@media only screen and (max-width: 1023px) {
  .nav-sub-menus {
    flex-direction: column;
  }

  .nav-icon {
    display: none;
  }

  .nav-divider {
    margin-left: 5px;
    margin-right: 5px;
    height: 40%;
  }

  .nav-text {
    font-size: 13px;
  }

  .arrow-close {
    transform: scale(.8);
    margin-right: 5px;
  }

  .second-col-links-holder {
    width: 100%;
  }

  .mn-thirdcol {
    flex: none;
  }

}

@media only screen and (max-width: 700px) {

  .navBG {
    background-color: black;
  }

  .nav-icon {
    filter: invert(1);
  }

  .nav-sub-menus {
    flex-direction: column;
  }

  .nelson-logo {
    display: none;

  }

  .nav-divider {
    display: none;
  }

  .nav-hidden-element {
    display: none;
  }

  .nav-icon {
    display: block;
    transform: scale(.8);
  }

  .nav-links {
    margin: 0;
    width: 100%;
  }

  .nav-text {
    margin: 4px;
  }



  .mn-link {
    font-size: 12px;
    letter-spacing: .03em;
    line-height: 1.1em;
    padding: 9px 5px;
    max-height: none;
  }

}

@media only screen and (max-width: 375px) {
  .nav-icon {
    display: none;
  }
}


.solutions-nav {
  flex-direction: column !important;
}

.nav-expand {
  min-width: 15px;
  max-width: 15px;
  min-height: 15px;
  max-height: 15px;
  filter: invert(1);
}

.chevron-down {
  width: 15px;
  height: 15px;
}

.close-black {
  width: 11px;
  height: 11px;
  transform: translate(2px, 3px);
}

.nav-holder {
  position: absolute;
  z-index: 1;
  width: 100%;

}

.white-bg {
  background-color: white;
}

.logo-nelson-sized {
  transform: translateX(-29px);
  filter: invert(1);
}

.bi-search {
  transform: scale(.8);
}
</style>