<template>
  <div class="nav-alpha-scroll-holder">
    <div class="nav-alpha-scroll">
      <div class="nav-alpha-letter" v-for="(item, index) in getAlpha" @click="handleAlpha(item)">
        {{ item.toUpperCase() }}
      </div>
     </div>
  </div>
</template>

<script>


export default {
  setup() {},
  components: {},
  data() {
    return {

    }
  },
  props: [

  ],


  computed: {
    getAlpha () {
        return '#abcdefghijklmnopqrstuvwxyz'.split('');
      }
  },
  methods : {
    handleAlpha (item) {
      this.$emit('handle-a-scroll', item);
    }
  }

}
</script>

<style scoped>
.nav-alpha-scroll {
  width: 20px;
  height: 100%;
  background-color: #0D7BC6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.nav-alpha-scroll-holder {
  position: sticky;
  top: 0;

}

.nav-alpha-letter {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  font-size: 11px;
  height: 100%;
  width: 100%;
}

.nav-alpha-letter:hover {
  background-color: #09588a;
  transition: .15s;
}

</style>